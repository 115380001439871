import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const FooterWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 36px 0px;
`;

const ItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
    }
`;

const ItemLink = styled(Link)`
    padding: 12px;
    text-decoration: none;
    color: rgba(236, 238, 248, 1);
`;

const CompanyName = styled.p`
    color: rgba(236, 238, 248, 1);
`;

const footer = [
    {
        text: "Privacy",
        link: "/privacy",
    },
];

const Header = () => (
    <FooterWrapper>
        <StaticImage
            width={146}
            height={128}
            src="../images/knife.png"
            alt="knife"
            placeholder="blurred"
            quality={100}
        />

        <ItemWrapper>
            {footer.map((item) => (
                <ItemLink key={item.text} to={item.link}>
                    {item.text}
                </ItemLink>
            ))}
        </ItemWrapper>
        <CompanyName>© Liatorpet AB</CompanyName>
    </FooterWrapper>
);

export default Header;
