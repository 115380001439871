import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const HeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    align-items: center;
    justify-content: space-between;
    color: #313444;
    top: 0;
    margin: 0;
    padding-top: 0.5rem;

    @media (max-width: 720px) {
        flex-direction: column;
    }
`;

const MenuButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 720px) {
        width: 100%;
    }
`;

const LogoText = styled.span`
    font-size: 1.5rem;
    padding-left: 0.5rem;
    font-weight: 700;
`;

const Header = () => {
    return (
        <HeaderWrapper>
            <MenuButtonWrapper>
                <Link to={"/"}>
                    <StaticImage
                        height={56}
                        src="../images/kepsHeader.png"
                        alt="jakt kalender"
                        placeholder="blurred"
                        objectFit="contain"
                        objectPosition="center"
                    />
                </Link>
            </MenuButtonWrapper>
        </HeaderWrapper>
    );
};

export default Header;
