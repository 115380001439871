import "./src/styles/global.css";
import Layout from "layout/layout";
import * as React from "react";

export const wrapPageElement = ({ element, props }) => {
    if (props.pageContext.layout === "ARTICLE_NAV") {
        return <Layout {...props}>{element}</Layout>;
    }
    return <Layout {...props}>{element}</Layout>;
};
