import React from "react";
import styled from "styled-components";
import Header from "./header";
import Footer from "./footer";
import backgroundImage from "../images/background-image.jpg";

const AppWrapper = styled.div`
    min-width: 100%;
    background-image: url(${backgroundImage});
    background-size: cover;
`;

const Layout = ({ children }) => (
    <AppWrapper>
        <Header />
        {children}
        <Footer />
    </AppWrapper>
);

export default Layout;
